/* You can add global styles to this file, and also import other style files */
@import './styles/bootstrap/bootstrap';
@import './styles/base.scss';
@import './styles/debug.scss';

@import './styles/object-fit.scss';

@import './styles/typography.scss';
@import './styles/main.scss';

@import './styles/helpers.scss';
@import './styles/rte.scss';
@import './styles/container';

@import './styles/themes';
@import './styles/slider';

@import './styles/lazyload';

@import "./styles/spacing";

@import "./styles/focus-visible-trick";

@import "./styles/block-utils.scss";
