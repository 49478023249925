// .contain {object-fit: contain;}
.object-fit-cover {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
}

.object-fit-contain {
  width: 100%;
  height: 100%;

  object-fit: contain;
  object-position: center center;
  font-family: 'object-fit: contain; object-position: center center;';
}
