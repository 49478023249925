@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@mixin p-default {
  @include p-vertical();
  @include p-horizontal();
}

@mixin p-horizontal {
  @include p-left();
  @include p-right();
}

@mixin p-vertical {
  @include p-top();
  @include p-bottom();
}

@mixin p-bottom {
 padding-bottom: 20px;

 // 800px
  @include media-breakpoint-up(md) { padding-bottom: 40px; }

 // 1000
  // @include media-breakpoint-up(lg) { padding-bottom: 40px; }

 // 1600px
  // @include media-breakpoint-up(lg) { padding-bottom: 100px; }
}

@mixin p-top {
 padding-top: 32px;

 // 800px
  @include media-breakpoint-up(md) { padding-top: 40px; }

 // 1000
  // @include media-breakpoint-up(lg) { padding-top: 40px; }

 // 1600px
//  @include media-breakpoint-up(lg) { padding-top: 100px; }
}


@mixin p-left {
 padding-left: 20px;
 // 800px
  @include media-breakpoint-up(md) { padding-left: 40px; }

 // 1000
  @include media-breakpoint-up(lg) { padding-left: 6.95vw; }

 // 1600px
  @include media-breakpoint-up(xl) { padding-left: 100px; }
}

@mixin p-right {
 padding-right: 20px;

 // 800px
  @include media-breakpoint-up(md) { padding-right: 40px; }

 // 1000
  @include media-breakpoint-up(lg) { padding-right: 6.95vw; }

 // 1600px
  @include media-breakpoint-up(xl) { padding-right: 100px; }
}
