@import "../shared.scss";

.v-gutters {
    margin-bottom: -$grid-gutter-width;

    > .col,
    > [class*='col-'] {
        padding-bottom: $grid-gutter-width;
    }
}
