@import './shared';

.lazyload-picture {
  position: relative;
  display: block;
  overflow: hidden;

  &:before {
    content: ' ';
    position: absolute;
    display: block;
    background-color: $stx-primary;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    transition: transform ease-in-out 300ms;
  }

  &.is-ready::before {
    transform: translateX(101%);
  }

  &.ratio-450-640 {
    padding-bottom: 640/450 *100%;
  }

  &.ratio-16-9 {
    padding-bottom: 56.25%;
  }

  &.ratio-1-1 {
    padding-bottom: 100%;
  }
}

.lazyload-img {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &.add-padding {
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }
}
