@import "./shared";

.slider-view {
  padding: 0 $grid-gutter-width;

  @include media-breakpoint-up(md) {
    padding: 0 12.5%;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 25%;
  }

}
.slider-item {
  flex-grow: 1;
  min-width: 100%;
  padding: 0 7.5px;
  @include media-breakpoint-up(md) {
    padding: 0 12.5px;
  }

}
