* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
  font-size: $h1-font-size * 0.5;
  line-height: 1.2em;
  margin-bottom: 16px;

  @include media-breakpoint-up(sm) {
    font-size: $h1-font-size * 0.75;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h1-font-size;
  }

  // Hack to allow larger text in Hero module
  @media only screen and (min-width: 990px) and (max-width: 1100px) {
    font-size: $h1-font-size * 0.80;
  }
}

h2,
.h2 {
  font-size: $h2-font-size * 0.75;
  line-height: 1.2em;
  margin-bottom: 16px;

  @include media-breakpoint-up(sm) {
    font-size: $h2-font-size * 0.75;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h2-font-size;
  }
}

h3,
.h3 {
  font-size: $h3-font-size * 0.75;
  line-height: 1.2em;
  margin-bottom: 16px;

  @include media-breakpoint-up(lg) {
    font-size: $h3-font-size;
  }
}

h4,
.h4 {
  font-size: $h4-font-size;
  line-height: 1.2em;
  margin-bottom: 16px;
}

h5,
.h5 {
  font-size: $h5-font-size;
  font-weight: 600;
  line-height: 1.2em;
  margin-bottom: 16px;
}

h1,
h2,
h3 {
  @include media-breakpoint-up(lg) {
    max-width: 810px;
    margin-left: auto;
    margin-right: auto;
  }
}

h4,
h5,
p {
  @include media-breakpoint-up(lg) {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
}

p {
  font-size: $font-size-sm;
  line-height: 18px;
  margin-bottom: 16px;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-base;
    line-height: 21px;
    margin-bottom: 24px;
  }
}

.p-small {
  font-size: $font-size-sm;
  line-height: $font-size-sm * 1.5;
}

.p-large {
  font-size: $font-size-lg;
  line-height: $font-size-lg * 1.5;
}

blockquote,
.blockquote {
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  color: #141a5b;
}

.small {
  // font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
