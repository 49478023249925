.block-link {
  position: relative;
  cursor: pointer;
}

.block-link-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}
