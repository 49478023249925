html {
  width: 100%;
  min-height: 100%;
}

body {
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
}

//Still need a place for these
// .card {
//   margin: 10px 0px;
//   @include media-breakpoint-between(sm, md) {
//     margin: 20px 40px
//   }
  .card-title
  // a
  {
    font-weight: $font-weight-bold;
  }
// }

figure {margin: 0}
