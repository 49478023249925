@import "../styles/shared";

.theme-default {
  background-color: #f8f8f8 !important;
  color: #141a5b !important;

  p {
    color: #535ea0 !important;
  }
}

.theme-dark {
  background-color: #141a5b !important;
  color: $sdx-primary-50 !important;

  h1, h2, h3, h4, h5 {
    color: $sdx-primary-50;
  }

  p {
    color: #c0c4dd !important;
  }
}

.theme-green {
  background-color: #4DE8D1 !important;
  color: #141A5B !important;

  h1, h2, h3, h4, h5 {
    color: #141A5B;
  }

  p {
    color: #535ea0 !important;
  }
}

.theme-dark-green {
  background-color: #141A5B !important;
  color: #4DE8D1 !important;

  h1, h2, h3, h4, h5 {
    color: #4DE8D1;
  }

  p {
    color: #4DE8D1 !important;
  }
}


.campaign-content {
  color: $sdx-primary-50;
  h1, h2, h3, h4, h5 {
    color: $sdx-primary-50;
  }
}
