.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
}

.main-content {
  max-width: map-get($container-max-widths, xl);
  margin: 0 auto;
  flex-grow: 1;
}

.main-container {
  max-width: map-get($container-max-widths, xl);
  margin: 0 auto;
}
