@import './shared';
@import './mixins';

.container-01 {
  //container-all-padded
  @include p-horizontal;
  // @include p-top;
}

.container-02 {
  //container-inner
  margin: 0 auto;
  max-width: 820px;
}

.container-03 {
  //container-small-full
  @include media-breakpoint-up(lg) {
    padding-right:6.95vw;
    padding-left:6.95vw;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 100px;
    padding-left: 100px;
  }
}
