// Padding value no, medium, large .
// Desktop:
// no: 0px
// Default: 60px
// Large: 90px

// Tablet:
// no: 0px
// Default: 40px
// Large: 70px

// Mobile:
// no: 0px
// Default: 20px
// Large: 40px

.spacing-top-none {
  padding-top: 0;
}

.spacing-bottom-none {
  padding-bottom: 0;
}

.spacing-top-small {
  padding-top: 10px;
  @include media-breakpoint-up(md) {
    padding-top: 20px;
  }
}

.spacing-bottom-small {
  padding-bottom: 10px;
  @include media-breakpoint-up(md) {
    padding-bottom: 20px;
  }
}


.spacing-top-medium {
  padding-top: 20px;
  @include media-breakpoint-up(md) {
    padding-top: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 60px;
  }
}

.spacing-bottom-medium {
  padding-bottom: 20px;
  @include media-breakpoint-up(md) {
    padding-bottom: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 60px;
  }
}

.spacing-top-large {
  padding-top: 40px;
  @include media-breakpoint-up(md) {
    padding-top: 70px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 90px;
  }
}

.spacing-bottom-large {
  padding-bottom: 40px;
  @include media-breakpoint-up(md) {
    padding-bottom: 70px;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 90px;
  }
}
