body {
    @media screen {
      &.debug {

        &:before {
          position: fixed;
          bottom: 0;
          left: 0;
          color: white;
          z-index: 99;
          padding: 10px 20px;

          @include media-breakpoint-between(xs, sm) {
            background: navy;
            content: 'xs to sm';
          }

          @include media-breakpoint-between(sm, md) {
            background: black;
            content: 'sm to md';
          }

          @include media-breakpoint-between(md, lg) {
            background: green;
            content: 'md to lg';
          }

          @include media-breakpoint-between(lg, xl) {
            background: orange;
            content: 'lg to xl';
          }

          @include media-breakpoint-up(xl) {
            background: red;
            content: 'from xl';
          }

          @include media-breakpoint-up(xxl) {
            background: purple;
            content: 'from xxl';
          }

          @include media-breakpoint-up(xxxl) {
            background: pink;
            content: 'from xxxl';
          }
        }
      }
    }
  }
