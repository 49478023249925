.rte {
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }

  // ul,
  // ol {
  //   padding-left: 20px;
  // }

  // li {
  //   color: #141a5b;
  //   font-size: 12px;
  //   font-weight: 600;
  //   padding-bottom: 8px;

  //   @include media-breakpoint-up(sm) {
  //     padding-bottom: 16px;
  //     font-size: 16px;
  //   }
  // }

  // ul li {
  //   list-style-image: url("../assets/bullet-list-icon.png");
  // }
}
